<template>
  <div>
    <AddNewTeam
      v-model="isAddNewTeam"
      @onAdd="fetchDataTable"
    />
    <EditTeam
      v-model="isEditTeam"
      :data-edit="dataEdit"
      @onUpdate="fetchDataTable"
    />
    <TeamUserList
      v-model="isShowUserTeamList"
      :team-id="teams_id"
      :team-name="teams_name"
    />
    <v-card>
      <v-card-title class="px-2">
        {{ $t('System user team') }} <v-spacer></v-spacer>
        <v-btn
          color="primary"
          class="d-none d-md-block"
          @click="isAddNewTeam = true"
        >
          <v-icon class="me-1">
            {{ icons.mdiPlus }}
          </v-icon>
          {{ $t('add_team') }}
        </v-btn>
        <v-btn
          color="primary"
          class="d-block d-md-none"
          fab
          outlined
          icon
          @click="isAddNewTeam = true"
        >
          <v-icon>
            {{ icons.mdiPlus }}
          </v-icon>
        </v-btn>
      </v-card-title>
      <v-row class="px-2">
        <v-col
          cols="12"
          lg="4"
          class="py-0"
        >
          <v-text-field
            v-model="searchtext"
            :label="$t('search')"
            :placeholder="$t('wordingSearch')"
            dense
            outlined
          ></v-text-field>
        </v-col>
        <v-col
          cols="12"
          lg="4"
          class="py-0"
        >
          <v-select
            v-model="teams_status_id"
            :placeholder="$t('status')"
            :label="$t('status')"
            :items="statusList"
            :item-text="$i18n.locale"
            item-value="value"
            outlined
            dense
          ></v-select>
        </v-col>
      </v-row>
      <v-data-table
        :headers="tableColumns"
        :items="dataTableList"
        :server-items-length="totalDataTableList"
        :loading="loading"
        :options.sync="options"
        hide-default-footer
        disable-sort
        :loading-text="$t('data_loading')"
        :no-data-text="$t('no_information')"
      >
        <template v-slot:[`item.teams_id`]="{ index }">
          {{ index + 1 }}
        </template>
        <template v-slot:[`item.teams_status_id`]="{ item }">
          <StatusBlock :status="item.teams_status_id" />
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                small
                color="primary"
                v-bind="attrs"
                @click="teams_name = item.teams_name, teams_id=item.teams_id, isShowUserTeamList = true"
                v-on="on"
              >
                <v-icon>{{ icons.mdiPlaylistEdit }}</v-icon>
              </v-btn>
            </template>
            <span>{{ $t("manage_team") }}</span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                small
                color="primary"
                v-bind="attrs"
                @click="isEditTeam = true, dataEdit = item"
                v-on="on"
              >
                <v-icon>{{ icons.mdiPencilOutline }}</v-icon>
              </v-btn>
            </template>
            <span>{{ $t("edit") }}</span>
          </v-tooltip>
          <v-tooltip
            v-if="item.teams_status_id == '1'"
            top
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                small
                color="primary"
                v-bind="attrs"
                @click="teamStatus = item.teams_status_id, teams_id = item.teams_id,isDeleteTeam = true"
                v-on="on"
              >
                <v-icon>{{ icons.mdiDeleteOutline }}</v-icon>
              </v-btn>
            </template>
            <span>{{ $t("suspend") }}</span>
          </v-tooltip>
          <v-tooltip
            v-if="item.teams_status_id == '2'"
            top
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                small
                icon
                color="primary"
                v-bind="attrs"
                @click="teamStatus = item.teams_status_id, teams_id = item.teams_id,isDeleteTeam = true"
                v-on="on"
              >
                <v-icon>{{ icons.mdiCheck }}</v-icon>
              </v-btn>
            </template>
            <span>{{ $t("normal") }}</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-card>

    <v-dialog
      v-model="isDeleteTeam"
      persistent
      width="300px"
    >
      <v-card>
        <v-card-title>
          <v-spacer></v-spacer>
          <v-icon
            class="me-1"
            color="warning"
          >
            {{ icons.mdiAlertOutline }}
          </v-icon> {{ teamStatus ==1? $t('confirm_teams_suspend') :$t('cancel_teams_suspend') }}
          <v-spacer></v-spacer>
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            :loading="deleteLoading"
            :disabled="deleteLoading"
            @click="teamStatus==1? handleSuspendTeam():handleUnSuspendTeam()"
          >
            {{ $t('confirm') }}
          </v-btn>
          <v-btn
            color="secondary"
            outlined
            @click="isDeleteTeam = false"
          >
            {{ $t('cancel') }}
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import {
  mdiPlaylistEdit, mdiPencilOutline, mdiDeleteOutline, mdiPlus, mdiCheck, mdiAlertOutline,
} from '@mdi/js'
import { ref } from '@vue/composition-api'
import useTeamList from './useTeamList'
import StatusBlock from '@/components/basicComponents/StatusBlock.vue'
import AddNewTeam from './AddNewTeam.vue'
import EditTeam from './EditTeam.vue'
import TeamUserList from './TeamUserList.vue'
import team from '@/api/systemSetting/team'
import store from '@/store'

export default {
  components: {
    StatusBlock,
    AddNewTeam,
    EditTeam,
    TeamUserList,
  },
  setup() {
    const isAddNewTeam = ref(false)
    const isEditTeam = ref(false)
    const isDeleteTeam = ref(false)
    const teams_id = ref('')
    const teams_name = ref('')
    const dataEdit = ref({})

    const deleteLoading = ref(false)

    const teamStatus = ref('')

    const isShowUserTeamList = ref(false)

    const {
      tableColumns,
      dataTableList,
      options,
      totalDataTableList,
      loading,
      searchtext,
      statusList,
      teams_status_id,
      fetchDataTable,
    } = useTeamList()

    const { suspendTeam, unSuspendTeam } = team

    const handleSuspendTeam = () => {
      deleteLoading.value = true
      const body = {
        teams_id: teams_id.value,
        teams_status_id: 2,
      }
      suspendTeam(body).then(res => {
        store.commit('app/ALERT', {
          message: res.message,
          color: res.response ? 'success' : 'error',
        })
        deleteLoading.value = false
        isDeleteTeam.value = false
        fetchDataTable()
      })
    }
    const handleUnSuspendTeam = () => {
      deleteLoading.value = true
      const body = {
        teams_id: teams_id.value,
        teams_status_id: 1,
      }
      unSuspendTeam(body).then(res => {
        store.commit('app/ALERT', {
          message: res.message,
          color: res.response ? 'success' : 'error',
        })
        deleteLoading.value = false
        isDeleteTeam.value = false
        fetchDataTable()
      })
    }

    return {
      teamStatus,
      deleteLoading,
      isShowUserTeamList,
      teams_name,
      fetchDataTable,
      handleUnSuspendTeam,
      handleSuspendTeam,
      teams_id,
      isDeleteTeam,
      isAddNewTeam,
      tableColumns,
      options,
      dataTableList,
      totalDataTableList,
      loading,
      searchtext,
      statusList,
      teams_status_id,
      dataEdit,
      isEditTeam,
      icons: {
        mdiAlertOutline,
        mdiPlaylistEdit,
        mdiPencilOutline,
        mdiDeleteOutline,
        mdiPlus,
        mdiCheck,
      },

    }
  },
}
</script>
