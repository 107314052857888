<template>
  <div>
    <v-dialog v-model="isEditTeam" persistent max-width="500">
      <v-card>
        <v-card-title>
          {{ $t('edit_team') }}
        </v-card-title>
        <v-form ref="formEditTeam" @submit.prevent="createTeam">
          <v-card-text>
            <v-text-field
              v-model.trim="dataEditLocal.teams_name"
              dense
              outlined
              :rules="[required]"
              :label="`${$t('name')}${$t('team')}`"
            ></v-text-field>
            <v-text-field
              v-model.trim="dataEditLocal.teams_commis1"
              dense
              class="mt-3"
              outlined
              :label="$t('percent_commission_drug')"
              type="number"
            ></v-text-field>
            <v-text-field
              v-model.trim="dataEditLocal.teams_commis2"
              dense
              class="mt-3"
              outlined
              :label="$t('percent_commission_equipment')"
              type="number"
            ></v-text-field>
            <v-text-field
              v-model.trim="dataEditLocal.teams_commis3"
              dense
              class="mt-3"
              outlined
              :label="$t('precent_commission_course')"
              type="number"
            ></v-text-field>
            <v-text-field
              v-model.trim="dataEditLocal.teams_commis4"
              dense
              class="mt-3"
              outlined
              :label="$t('precent_commission_member')"
              type="number"
            ></v-text-field>
            <v-text-field
              v-model.trim="dataEditLocal.teams_commis5"
              dense
              class="mt-3"
              outlined
              :label="$t('precent_commission_check')"
              type="number"
            ></v-text-field>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" type="submit" :loading="loading" :disabled="loading">
              {{ $t('save') }}
            </v-btn>
            <v-btn color="secondary" outlined @click="$emit('update:is-edit-team', false)">
              {{ $t('cancel') }}
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { ref, watch } from '@vue/composition-api'
import { required } from '@/@core/utils/validation'
import team from '@/api/systemSetting/team'
import store from '@/store'

export default {
  model: {
    prop: 'isEditTeam',
    event: 'update:is-edit-team',
  },
  props: {
    isEditTeam: {
      type: Boolean,
      default: false,
    },
    dataEdit: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props, { emit }) {
    const formEditTeam = ref(null)

    const dataEditLocal = ref({})

    const loading = ref(false)

    const { teamUpdate } = team

    const createTeam = () => {
      const isFormValid = formEditTeam.value.validate()
      if (!isFormValid) return
      loading.value = true
      const body = {
        teams_id: dataEditLocal.value.teams_id,
        teams_name: dataEditLocal.value.teams_name,
        teams_commis1: dataEditLocal.value.teams_commis1,
        teams_commis2: dataEditLocal.value.teams_commis2,
        teams_commis3: dataEditLocal.value.teams_commis3,
        teams_commis4: dataEditLocal.value.teams_commis4,
        teams_commis5: dataEditLocal.value.teams_commis5,
      }
      teamUpdate(body).then(res => {
        store.commit('app/ALERT', {
          message: res.message,
          color: res.response ? 'success' : 'error',
        })
        loading.value = false
        formEditTeam.value.reset()
        emit('update:is-edit-team', false)
        emit('onUpdate', res.response)
      })
    }

    watch(
      () => props.dataEdit,
      newVal => {
        dataEditLocal.value = JSON.parse(JSON.stringify(newVal))
      },
    )

    return {
      dataEditLocal,
      loading,
      formEditTeam,
      required,
      createTeam,
    }
  },
}
</script>
