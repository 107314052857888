<template>
  <div>
    <v-dialog v-model="isAddNewTeam" persistent max-width="500">
      <v-card>
        <v-card-title>
          {{ $t('add_team') }}
        </v-card-title>
        <v-form ref="formAddTeam" @submit.prevent="createTeam">
          <v-card-text>
            <v-text-field
              v-model.trim="teams_name"
              dense
              outlined
              :rules="[required]"
              :label="`${$t('name')}${$t('team')}`"
            ></v-text-field>
            <v-text-field
              v-model.trim="teams_commis1"
              dense
              class="mt-3"
              outlined
              :label="$t('percent_commission_drug')"
              type="number"
            ></v-text-field>
            <v-text-field
              v-model.trim="teams_commis2"
              dense
              class="mt-3"
              outlined
              :label="$t('percent_commission_equipment')"
              type="number"
            ></v-text-field>
            <v-text-field
              v-model.trim="teams_commis3"
              dense
              class="mt-3"
              outlined
              :label="$t('precent_commission_course')"
              type="number"
            ></v-text-field>
            <v-text-field
              v-model.trim="teams_commis4"
              dense
              class="mt-3"
              outlined
              :label="$t('precent_commission_member')"
              type="number"
            ></v-text-field>
            <v-text-field
              v-model.trim="teams_commis5"
              dense
              class="mt-3"
              outlined
              :label="$t('precent_commission_check')"
              type="number"
            ></v-text-field>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" type="submit" :loading="loading" :disabled="loading">
              {{ $t('save') }}
            </v-btn>
            <v-btn color="secondary" outlined @click="$emit('update:is-add-new-team', false)">
              {{ $t('cancel') }}
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { ref, watch } from '@vue/composition-api'
import { required } from '@/@core/utils/validation'
import team from '@/api/systemSetting/team'
import store from '@/store'

export default {
  model: {
    prop: 'isAddNewTeam',
    event: 'update:is-add-new-team',
  },
  props: {
    isAddNewTeam: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const teams_name = ref('')
    const teams_commis1 = ref(0)
    const teams_commis2 = ref(0)
    const teams_commis3 = ref(0)
    const teams_commis4 = ref(0)
    const teams_commis5 = ref(0)
    const formAddTeam = ref(null)
    const loading = ref(false)

    const { teamAdd } = team

    const createTeam = () => {
      const isFormValid = formAddTeam.value.validate()
      if (!isFormValid) return
      loading.value = true
      const body = {
        teams_name: teams_name.value,
        teams_commis1: teams_commis1.value,
        teams_commis2: teams_commis2.value,
        teams_commis3: teams_commis3.value,
        teams_commis4: teams_commis4.value,
        teams_commis5: teams_commis5.value,
      }
      teamAdd(body).then(res => {
        store.commit('app/ALERT', {
          message: res.response ? 'completed_successfully' : 'unsuccessful',
          color: res.response ? 'success' : 'error',
        })
        loading.value = false
        formAddTeam.value.reset()
        emit('update:is-add-new-team', false)
        emit('onAdd', res.response)
      })
    }
    watch(
      () => props.isAddNewTeam,
      newValue => {
        if (newValue) {
          teams_name.value = ''
          teams_commis1.value = 0
          teams_commis2.value = 0
          teams_commis3.value = 0
        }
      },
    )

    return {
      loading,
      formAddTeam,
      teams_name,
      teams_commis1,
      teams_commis2,
      teams_commis3,
      teams_commis4,
      teams_commis5,
      required,
      createTeam,
    }
  },
}
</script>
<style lang=""></style>
