import { onBeforeMount, ref, watch } from '@vue/composition-api'
import basicStatus from '@/@fake-db/data/basicStatus.json'
import team from '@/api/systemSetting/team'
import { i18n } from '@/plugins/i18n'

export default function useTeamList() {
  // filters valiable
  const searchtext = ref('')
  const teams_status_id = ref(1)
  const statusList = basicStatus.data

  // data table valiable
  const tableColumns = [
    { text: '#', align: 'start', value: 'teams_id' },
    { text: i18n.t('team'), value: 'teams_name' },
    {
      text: i18n.t('percent_commission_drug'),
      value: 'teams_commis1',
      width: 210,
      align: 'end',
    },
    {
      text: i18n.t('percent_commission_equipment'),
      value: 'teams_commis2',
      align: 'end',
      width: 250,
    },
    {
      text: i18n.t('precent_commission_course'),
      value: 'teams_commis3',
      align: 'end',
      width: 220,
    },
    {
      text: i18n.t('precent_commission_member'),
      value: 'teams_commis4',
      align: 'end',
      width: 230,
    },
    {
      text: i18n.t('precent_commission_check'),
      value: 'teams_commis5',
      align: 'end',
      width: 230,
    },
    { text: i18n.t('status'), value: 'teams_status_id', align: 'center' },
    {
      text: i18n.t('option'),
      value: 'actions',
      align: 'center',
      width: 150,
    },
  ]
  const dataTableList = ref([])
  const totalDataTableList = ref(0)
  const options = ref({})
  const loading = ref(false)
  const { teamList } = team
  onBeforeMount(() => {
    fetchDataTable()
  })

  const fetchDataTable = () => {
    loading.value = true
    const body = {
      searchtext: searchtext.value,
      teams_status_id: teams_status_id.value,
    }

    teamList(body).then(res => {
      const { data, count } = res
      dataTableList.value = data
      totalDataTableList.value = count
      options.value.itemsPerPage = -1
      loading.value = false
    })
  }

  watch([searchtext, teams_status_id], (newvalue, oldvalue) => {
    if (newvalue !== oldvalue) {
      fetchDataTable()
    }
  })

  return {
    fetchDataTable,
    tableColumns,
    dataTableList,
    loading,
    options,
    teams_status_id,
    statusList,
    totalDataTableList,
    searchtext,
  }
}
