<template>
  <div>
    <v-dialog
      v-model="isShowUserTeamList"
      persistent
      max-width="1000"
    >
      <v-card>
        <v-card-title class="px-2">
          {{ $t('system_user_team') }} - {{ teamName }} <v-spacer></v-spacer>
          <v-btn
            color="primary"
            class="me-2 d-none d-md-block"
            @click="getUserTeamSelectList"
          >
            <v-icon class="me-1">
              {{ icons.mdiPlus }}
            </v-icon>

            <span class="d-none d-sm-block"> {{ $t('add_users_to_the_team') }}</span>
          </v-btn>
          <v-btn
            color="primary"
            class="me-2 d-block d-md-none"
            fab
            icon
            outlined
            @click="getUserTeamSelectList"
          >
            <v-icon>
              {{ icons.mdiPlus }}
            </v-icon>
          </v-btn>
          <v-btn
            color="error"
            fab
            icon
            outlined
            @click="$emit('update:is-show-user-team-list',false)"
          >
            <v-icon>
              {{ icons.mdiClose }}
            </v-icon>
          </v-btn>
        </v-card-title>
        <v-data-table
          :headers="tableColumns"
          :items="dataTableList"
          :loading="loading"
          :options.sync="options"
          hide-default-footer
          :mobile-breakpoint="0"
          disable-sort
          :loading-text="$t('data_loading')"
          :no-data-text="$t('no_information')"
        >
          <template v-slot:[`item.userteam_id`]="{ index }">
            {{ index + 1 }}
          </template>
          <template v-slot:[`item.userteam_status_id`]="{ item }">
            <StatusBlock :status="item.userteam_status_id" />
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-tooltip
              v-if="item.userteam_status_id == '1'"
              top
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  color="primary"
                  v-bind="attrs"
                  @click="showConfirmDelete(item)"
                  v-on="on"
                >
                  <v-icon>{{ icons.mdiDeleteOutline }}</v-icon>
                </v-btn>
              </template>
              <span>{{ $t("suspend") }}</span>
            </v-tooltip>
            <v-tooltip
              v-if="item.userteam_status_id == '2'"
              top
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  color="primary"
                  v-bind="attrs"
                  @click="showConfirmDelete(item)"
                  v-on="on"
                >
                  <v-icon>{{ icons.mdiCheck }}</v-icon>
                </v-btn>
              </template>
              <span>{{ $t("normal") }}</span>
            </v-tooltip>
            <v-btn
              v-if="item.userteam_status_id == '0'"
              disabled
              icon
              color="primary"
            >
              <v-icon>
                {{ icons.mdiCheck }}
              </v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="isAddNewUserTeam"
      persistent
      max-width="500"
    >
      <v-card>
        <v-card-title>
          {{ $t('add_users_to_the_team') }}
        </v-card-title>
        <v-form
          ref="formAddNewUserTeam"
          @submit.prevent="createNewUserTeam"
        >
          <v-card-text>
            <v-text-field
              v-model.trim="teamName"
              disabled
              outlined
              dense
              :label="$t('team')"
            ></v-text-field>
            <v-autocomplete
              v-model.trim="user_id"
              :items="userList"
              outlined
              dense
              :rules="[required]"
              :loading="loadingUserList"
              :label="$t('user')"
              :no-data-text="$t('no_information')"
              item-text="user_fullname"
              item-value="user_id"
            ></v-autocomplete>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              type="submit"
              :loading="processLoading"
              :disabled="processLoading"
            >
              {{ $t('save') }}
            </v-btn>
            <v-btn
              color="secondary"
              outlined
              @click="isAddNewUserTeam = false"
            >
              {{ $t('cancel') }}
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="isDeleteUser"
      persistent
      max-width="400"
    >
      <v-card>
        <v-card-title>
          <v-spacer></v-spacer>
          <v-icon
            class="me-1"
            color="warning"
          >
            {{ icons.mdiAlertOutline }}
          </v-icon> {{ userteam_status_id=='1'? $t('confirm_user_team_suspend'):$t('cancel_user_team_suspend') }}
          <v-spacer></v-spacer>
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            :loading="processLoading"
            :disabled="processLoading"
            @click="updateStatusUser"
          >
            {{ $t('confirm') }}
          </v-btn>
          <v-btn
            color="secondary"
            outlined
            @click="isDeleteUser = false"
          >
            {{ $t('cancel') }}
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { ref, watch } from '@vue/composition-api'
import {
  mdiClose, mdiPlus, mdiDeleteOutline, mdiCheck, mdiAlertOutline,
} from '@mdi/js'
import team from '@/api/systemSetting/team'
import { i18n } from '@/plugins/i18n'
import StatusBlock from '@/components/basicComponents/StatusBlock.vue'
import { required } from '@/@core/utils/validation'
import store from '@/store'

export default {
  components: {
    StatusBlock,
  },
  model: {
    prop: 'isShowUserTeamList',
    event: 'update:is-show-user-team-list',
  },
  props: {
    isShowUserTeamList: {
      type: Boolean,
      default: false,
    },
    teamId: {
      type: String,
      default: '',
    },
    teamName: {
      type: String,
      default: '',
    },
  },
  setup(props) {
    const dataTableList = ref([])
    const loading = ref(false)
    const options = ref({})
    const userteam_id = ref('')
    const teams_id = ref('')
    const userList = ref([])
    const loadingUserList = ref(false)
    const user_id = ref('')
    const userteam_status_id = ref('')
    const formAddNewUserTeam = ref(null)
    const processLoading = ref(false)
    const isDeleteUser = ref(false)

    const tableColumns = [
      {
        text: '#', align: 'start', value: 'userteam_id', width: 80,
      },
      { text: i18n.t('name'), value: 'user_fullname', width: 200 },
      { text: i18n.t('status'), value: 'userteam_status_id', width: 100 },
      {
        text: i18n.t('option'), value: 'actions', width: 100, align: 'center',
      },
    ]

    const isAddNewUserTeam = ref(false)

    const {
      teamUserList, teamUserSelectList, teamUserAdd, updateStatusUserTeam,
    } = team

    const getUserTeamList = () => {
      teamUserList(props.teamId).then(res => {
        dataTableList.value = res
        options.value.page = -1
        loading.value = false
      })
    }

    const getUserTeamSelectList = () => {
      user_id.value = ''
      isAddNewUserTeam.value = true
      loadingUserList.value = true
      teamUserSelectList(props.teamId).then(res => {
        userList.value = res
        loadingUserList.value = false
      })
    }

    const createNewUserTeam = () => {
      const isFormValid = formAddNewUserTeam.value.validate()
      if (!isFormValid) return
      processLoading.value = true
      const body = {
        teams_id: props.teamId,
        user_id: user_id.value,
      }
      teamUserAdd(body).then(res => {
        store.commit('app/ALERT', {
          message: res.message,
          color: res.response ? 'success' : 'error',
        })
        processLoading.value = false
        getUserTeamList()
        isAddNewUserTeam.value = false
      })
    }

    const showConfirmDelete = item => {
      userteam_id.value = item.userteam_id
      teams_id.value = item.teams_id
      user_id.value = item.user_id
      userteam_status_id.value = item.userteam_status_id
      isDeleteUser.value = true
    }

    const updateStatusUser = () => {
      processLoading.value = true
      const body = {
        userteam_id: userteam_id.value,
        teams_id: teams_id.value,
        user_id: user_id.value,
        userteam_status_id: userteam_status_id.value == '1' ? '2' : '1',
      }
      updateStatusUserTeam(body).then(res => {
        store.commit('app/ALERT', {
          message: res.message,
          color: res.response ? 'success' : 'error',
        })
        processLoading.value = false
        getUserTeamList()
        isDeleteUser.value = false
      })
    }

    watch(
      () => props.teamId,
      () => {
        if (props.teamId) {
          dataTableList.value = []
          loading.value = true
          getUserTeamList()
        }
      },
    )

    return {
      formAddNewUserTeam,
      loadingUserList,
      processLoading,
      userList,
      user_id,

      isAddNewUserTeam,
      dataTableList,
      loading,
      tableColumns,
      isDeleteUser,
      options,
      userteam_status_id,
      getUserTeamSelectList,
      updateStatusUser,
      showConfirmDelete,
      createNewUserTeam,
      required,
      icons: {
        mdiCheck, mdiClose, mdiPlus, mdiDeleteOutline, mdiAlertOutline,
      },
    }
  },

}
</script>
